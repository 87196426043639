.Portfolio{
    display: flex;  
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
}.card{
    display: flex;
    flex-direction: column;
    height: 50vh;
    margin: 2rem;
    background-color: #fff;
    border-radius: 24px;
    border-style: none;
    box-shadow: rgba(0, 0, 0, .2) 0 3px 5px -1px,rgba(0, 0, 0, .14) 0 6px 10px 0,rgba(0, 0, 0, .12) 0 1px 18px 0;
    box-sizing: border-box;
    color: #3c4043;
    fill: currentcolor;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: .25px;
    line-height: normal;
    overflow: visible;
    padding: 2px 24px;
    text-align: center;
    text-transform: none;
    transition: box-shadow 280ms cubic-bezier(.4, 0, .2, 1),opacity 15ms linear 30ms,transform 270ms cubic-bezier(0, 0, .2, 1) 0ms; 
    width: 30vw;
    will-change: transform,opacity;
    z-index: 0;
}

.projectlogo{
    height:40vh;
    width: auto;
}

